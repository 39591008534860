<template>
  <div>
    <p style="font-size: 22px; font-family: Lato">School wide Leaderboard</p>
    <div class="table-div">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :loading="loading"
        :items="items"
        loading-text="Loading... Please wait"
        class="elevation-8"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <div class="pt-5">
            <v-tabs
              v-model="selected"
              grow
              mobile-breakpoint
              background-color="transparent"
              color="red"
              class="px-20"
            >
              <v-tab
                v-for="(item, i) in tabs"
                :key="i"
                class="text-capitalize px-6"
                v-text="item.title"
                style="margin-left: 40px"
              ></v-tab>
            </v-tabs>
            <b><hr style="margin-bottom: 5px" /></b>
          </div>
        </template>

        <!-- customize header -->

        <template v-slot:item="row">
          <tr>
            <td class="text-center row-item py-4">
              <div style="margin-bottom: -5px" v-if="row.item.rank == 1">
                <img
                  height="25"
                  width="45"
                  src="@/assets/leaderBoardLogo.svg"
                />
              </div>
              <div style="font-weight: 600">{{ row.item.rank }}</div>
            </td>
            <td class="text-center row-item py-4">
              <div class="d-flex justify-center">
                <div style="float: left" class="px-2">
                  <img width="45" :src="row.item.logo_url" />
                </div>
                <div style="float: left" class="ml-2 text-left">
                  <div style="font-weight: 600">{{ row.item.school_name }}</div>
                  <div style="color: #777777">
                    {{ row.item.district_name }}
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                ${{ row.item.total_fund_raised }}
              </div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                {{ row.item.total_students_registered }}
              </div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">
                {{ row.item.wizfit_video }}/<span style="color: #777777">{{
                  row.item.total_wizfit_video
                }}</span>
              </div>
              <div style="color: #067605">
                {{ row.item.percentage_wizfit_video }}%
              </div>
            </td>
            <td class="text-center row-item py-4">
              <div style="font-weight: 600">{{ row.item.total_shares }}</div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { API_ADMIN_DASHBOARD_SCHOOL_LEADERBOARD } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapGetters } from "vuex";
import authToken from "@/common/authToken";
export default {
  name: "SchoolLeaderboardTable",
  components: {},
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: "Rank",
          align: "center",
          sortable: false,
          value: "rank",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          text: "School",
          align: "center",
          sortable: false,
          value: "school",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Fund Raised",
          align: "center",
          value: "reg_student",
          class: ["tableHeader-text", "tableHeader-bg"],
          color: "dark",
        },
        {
          sortable: false,
          text: "# Registered Student",
          align: "center",
          value: "student",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Wizfit Video",
          value: "video",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },

        {
          sortable: false,
          text: "# Shares",
          value: "shares",
          class: ["tableHeader-text", "tableHeader-bg"],
          align: "center",
        },
      ],
      tabs: [
        { title: "Most Funds Raised", sort_by: "total_fund_raised" },
        { title: "Top Registration", sort_by: "total_students_registered" },
        { title: "Most Video Watched", sort_by: "total_videos_watched" },
        { title: "Most Number of Shares", sort_by: "total_shares" },
      ],
      selected: 0,
      detailCount: 5,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    selected() {
      this.getSchoolLeaderData();
    },
  },
  methods: {
    getSchoolLeaderData() {
      const self = this;
      self.loading = true;

      const successHandler = (res) => {
        console.log(res.data);
        this.items = [];
        var i = 1;
        for (var obj of res.data.school_list) {
          let item_obj = {
            rank: i,
            district_name: obj.district_name,
            percentage_wizfit_video: obj.percentage_wizfit_video,
            logo_url: obj.logo_url,
            school_name: obj.school_name,
            total_fund_raised: obj.total_fund_raised,
            total_shares: obj.total_shares,
            total_students_registered: obj.total_students_registered,
            total_wizfit_video: obj.total_wizfit_video,
            wizfit_video: obj.wizfit_video,
          };
          this.items.push(item_obj);
          i++;
        }
        console.log(this.items);
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      let formData = {};
      formData["sorting_key"] = this.tabs[this.selected].sort_by;
      formData["detail_count"] = this.detailCount;
      if (this.layout === "CampaignDashboardLayout") {
        if (this.$route.matched[0].path.substring(1) === "district") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
        }
      }
      console.log(formData);
      Axios.request_GET(
        API_ADMIN_DASHBOARD_SCHOOL_LEADERBOARD,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.getSchoolLeaderData();
    }, 2000);
  },
};
</script>
<style scoped>
.active-class {
  border-right: 10px solid red;
  border-radius: 4px;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-8.v-data-table--has-top.theme--light {
  font-family: Lato;
  border-radius: 10px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--has-top.theme--light {
    min-width: 1100px;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
